import { AiOutlineMail, AiOutlineMobile } from 'react-icons/ai';
import { BtnBlack, BtnBlue } from '../components/shared/button';
import { Col, Result, Row, Spin } from 'antd';
import { H1, TextRegular } from '../components/shared/typography';
import React, { useState } from 'react';
import { SiteLayout, StyledContent } from '../components/layout/layout';

import { PageContext } from '../pageContext';
import { RiUserLocationLine } from 'react-icons/ri';
import Seo from '../components/Seo';
import emailjs from 'emailjs-com';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

const LoadingContainer = styled.div`
  min-height: 400px;
  display: flex;
`;

interface Props {
  pageContext: PageContext;
}

type Inputs = {
  example: string;
  exampleRequired: string;
};

const StyledForm = styled.form`
  label {
    font-weight: 600;
    font-size: 1rem;
  }
  input,
  textarea {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px 12px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background-color: transparent;
    color: inherit;
    text-align: inherit;
    height: auto;
    border-style: none;
    border: 1px solid grey;

    &:selection {
      border-style: none;
      border: 1px solid grey;
    }
  }
`;

const ContactPage: React.FC<Props> = ({ pageContext }: Props) => {
  const { register, handleSubmit } = useForm({mode: 'onSubmit'});
  const [loading, setLoading] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    const toSend: EmailForm = {
      companyName: data.companyName,
      name: data.name,
      address: data.address,
      phone: data.phone,
      email: data.email,
      comment: data.comment,
    };

    emailjs
      .send('service_vmvwktq', 'template_xum6uw8', toSend, 'user_AYCBPZbqyI4spzXzYgsWA')
      .then(
        function(response) {
          console.log('SUCCESS!', response.status, response.text);
          setEmailSuccess(true);
        },
        function(error) {
          setEmailError(true);
          console.log('FAILED...', error);
        },
      );
  };

  return (
    <SiteLayout
      locale={pageContext.locale}
      componentName={pageContext.componentName}
    >
      <Seo
        title="Kontakt os i dag!"
        description="Kontakt HighView allerede idag for et uforpligtende tilbud, eller hvis du ønsker mere information"
        image={'/HighView-Security-text-tlf.jpg'}
        lang={pageContext.locale}
        keywords={["AIA", "ADK", "Privat", "Sikkerhed", "IT Sikkerhed", "NOX alarmanlæg", "Jablotron JA100", "HighView", "Overvågning", "Erhvers sikkerhed", "adgangskontrolanlæg" ]}
        pathname="/kontakt"
      />
      <StyledContent>
        <Row gutter={[48,48]} style={{ marginTop: '2rem', padding: '1rem' }}>
          <Col xs={24} lg={12}>
            <H1>Kontakt os</H1>
            <TextRegular>
              Ønsker du et uforpligtende tilbud? Udfyld formen herunder, så kontakter vi dig!
            </TextRegular>
            {loading && (
              <LoadingContainer>
                {!emailSuccess && !emailError && (
                  <Spin style={{ margin: 'auto' }} size="large" />
                )}
                {emailSuccess && (
                  <Result
                    style={{ margin: 'auto' }}
                    status="success"
                    title="Successfully send email request!"
                    subTitle="We will get back to you as soon as we can!"
                  />
                )}
                {emailError && (
                  <Result
                    style={{ margin: 'auto' }}
                    status="500"
                    title="Sorry, something went wrong."
                    subTitle="Please try again. If the error keeps happening try call us instead"
                    extra={
                      <BtnBlue
                        onClick={() => {
                          setEmailError(false);
                          setLoading(false);
                        }}
                      >
                        Try again
                      </BtnBlue>
                    }
                  />
                )}
              </LoadingContainer>
            )}

            {!loading && (
              <StyledForm onSubmit={handleSubmit((d: any) => onSubmit(d))}>
                <label htmlFor="name">Navn*</label>
                <input
                  id="name"
                  {...register("name", {required: true})}
                  required
                  type="text"
                />
                <label htmlFor="company">Virksomhedsnavn</label>
                <input id="company" {...register("companyName")} type="text" />
                <label htmlFor="email">E-mail*</label>
                <input
                  id="email"
                  {...register("email", {required: true,
                    pattern: {
                      value: /\S+@\S+.\S+/,
                      message: 'Entered value does not match email format',
                    }
                  })}
                  type="email"
                  
                />
                <label htmlFor="phone">Telefon*</label>
                <input
                  id="phone"
                  {...register("phone", {required: true})}
                  type="tel"
                  required
                />
                <label htmlFor="msg">Kommentar</label>
                <textarea id="msg" {...register("comment")} />
                <BtnBlack
                  name="submit"
                  type="submit"
                  style={{
                    color: '#fff',
                    background: '#274c77',
                    width: '200px',
                    textAlign: 'center',
                    marginTop: '1rem',
                  }}
                >Send forespørgsel</BtnBlack>
              </StyledForm>
            )}
          </Col>
          <Col xs={24} lg={12}
            style={{
              color: '#fff',
              background: '#274c77',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <AiOutlineMobile
              size={46}
              style={{ alignSelf: 'center', margin: '1rem' }}
            />
            <Row justify="center">
              <p>+45 46180200</p>
            </Row>

            <AiOutlineMail
              size={46}
              style={{ alignSelf: 'center', margin: '1rem' }}
            />
            <Row justify="center">
              <p>info@highview.dk</p>
            </Row>
            <RiUserLocationLine
              size={46}
              style={{ alignSelf: 'center', margin: '1rem' }}
            />
            <Row justify="center">
              <p>
                Engløkke 5 <br />
                DK-4622 Havdrup
              </p>
            </Row>
          </Col>
        </Row>
      </StyledContent>
    </SiteLayout>
  );
};
export default ContactPage;

interface EmailForm {
  name: string;
  companyName: string;
  address: string;
  phone: string;
  email: string;
  comment: string;
}
